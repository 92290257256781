import React, { useState, useEffect } from "react";
import ratePlanAdapter from "../../../../adapters/ratePlanAdapter";
import {
  CustomTable,
  TableContainer,
  Th,
  Td,
  Tr,
} from "../../../../styles/rate_plans";

export const Table = ({ data, markRecord, markedRecords, translations }) => {
  const [ratePlans, setRatePlans] = useState([]);

  useEffect(() => {
    const ratePlansAdapted = ratePlanAdapter(data);
    ratePlansAdapted.sort(sortByPlanType);

    setRatePlans(ratePlansAdapted);
  }, [data]);

  function sortByPlanType(a, b) {
    const planTypeA = a.planType.toLowerCase();
    const planTypeB = b.planType.toLowerCase();

    if (planTypeA < planTypeB) {
      return -1;
    }
    if (planTypeA > planTypeB) {
      return 1;
    }
    return 0;
  }

  const isMarked = (id) => {
    return markedRecords.includes(id);
  };
  if (ratePlans.length === 0) {
    return <TableContainer>{translations.loading}</TableContainer>;
  }
  return (
    <TableContainer showToolBar={markedRecords.length > 0}>
      <CustomTable>
        <thead style={{ position: "sticky", top: "0", margin: "0" }}>
          <tr>
            <Th style={{ borderRadius: "5px 0px 0px 0px" }}>{translations.header_name}</Th>
            <Th>{translations.header_code}</Th>
            <Th>{translations.header_type}</Th>
            <Th>{translations.header_segment}</Th>
            <Th>{translations.header_customer_areas}</Th>
            <Th style={{ borderRadius: "0px 5px 0px 0px" }}>{translations.header_provider}</Th>
          </tr>
        </thead>
        <tbody>
          {ratePlans.map((record) => (
            <Tr
              isMarked={isMarked(record.id)}
              key={record.id}
              onClick={() => markRecord(record.id)}
            >
              <Td>
                <a href={`/admin/rate_plans/${record.id}/edit`}>
                  {record.name}
                </a>
              </Td>
              <Td>{record.code}</Td>
              <Td>{record.planType}</Td>
              <Td>
                {record.businessSegments && record.businessSegments.length > 0 ? record.businessSegments.map((segment) => (
                  <p className="m-0" key={segment.name}>{segment.name} </p>
                )) : record.businesSection}</Td>
              <Td>
                {record.customerAreas.map((area) => (
                  <p className="m-0" key={area.name}>{area.name} </p>
                ))}
              </Td>
              <Td>{record.provider}</Td>
            </Tr>
          ))}
        </tbody>
      </CustomTable>
    </TableContainer>
  );
};
