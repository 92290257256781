import React, { useState, useEffect } from "react";
import axios from "axios";
import telcoProductAdapter from "../../../adapters/telcoProductAdapter.jsx";
import toast, { Toaster } from "react-hot-toast";
import {
  SubmitButton,
  Container,
  Title1,
  Title2,
  ProductsWrapper,
} from "../../../styles/individualComponents/AddTelcoProduct";
import Select from "../../reactComponents/select/Select";
import { FilterComponents } from "./FilterComponents";
import ProductSelection from "./ProductSelection";

function AddProductTelco({ telco_products, env_color, telco_invoice }) {
  const [productsTelco, setProductsTelco] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productType, setProductType] = useState(null);
  const [showProductSelect, setShowProductSelect] = useState(false);
  const [providerId, setProviderId] = useState(telco_invoice.provider_id)
  const [invoiceType, setInvoiceType] = useState(telco_invoice.invoiceType)
  const [mobileFilters, setMobileFilters] = useState({ // Setting of the mobile filters
    minsNationalsUnlimited: false,
    minsInternationalsUnlimited: false,
    smsNationalsUnlimited: false,
    smsInternationalsUnlimited: false,
    tech3G: false,
    tech4G: false,
    tech5G: false,
    minsNationals: 0,
    minsInternationals: 0,
    isDataShared: false,
    isDataUnlimited: false,
    numGb: 0,
    roaming: []
  });

  const [landlineFilters, setLandlineFilters] = useState({ // Setting of the land line filters
    landlineIsMinsNationalsUnlimited: false,
    landlineIsMinsInternationalsUnlimited: false,
    landlineNumMinNationals: 0,
    landlineNumMinInternationals: 0,
  });

  const [fiberFilters, setFiberFilters] = useState({ // Setting of the fiber filters
    fibreType: null,
    fibreSpeed: 100,
  });

  useEffect(() => {
    if (telco_products) {
      const adaptedProducts = telcoProductAdapter(telco_products); // Adapter in order to make easy the access to the variables
      setProductsTelco(adaptedProducts);
      setFilteredProducts([]);
    }
  }, [telco_products]);

  useEffect(() => {
    const handleModalClose = () => {
      resetForm();
    };
    window.addEventListener('modalClosed', handleModalClose);
    return () => {
      window.removeEventListener('modalClosed', handleModalClose);
    }
  }, []);
  
  useEffect(() => {
    // Definir una función global para recibir el cambio del select
    window.dispatchProviderChange = (providerId) => {
      setProviderId(parseInt(providerId, 10));
      setProductsTelco(telcoProductAdapter(telco_products.filter(product => product.provider_id == providerId)))
    };
    // Limpiar la función al desmontar el componente
    return () => {
      delete window.dispatchProviderChange;
    };
  }, []);

  useEffect(() => {
    window.dispatchInvoiceTypeChange = (invoiceType) => {
      setInvoiceType(invoiceType)
    }
  }, [])

  useEffect(() => {
    if (productsTelco.length > 0 && productType) {
      let filtered = productsTelco.filter(product => product.type.toLowerCase() === productType.toLowerCase());

      if (productType === 'movil') { // Mobile filters
        if (mobileFilters.minsNationalsUnlimited) {
          filtered = filtered.filter(product => product.mobileIsMinsNationalsUnlimited);
        } else {
          filtered = filtered.filter(product => product.mobileNumMinNationals >= mobileFilters.minsNationals);
        }
        if (mobileFilters.minsInternationalsUnlimited) {
          filtered = filtered.filter(product => product.mobileIsMinsInternationalsUnlimited);
        } else {
          filtered = filtered.filter(product => product.mobileNumMinInternationals >= mobileFilters.minsInternationals);
        }
        if (mobileFilters.smsNationalsUnlimited) {
          filtered = filtered.filter(product => product.isTextNationalsUnlimited);
        }
        if (mobileFilters.smsInternationalsUnlimited) {
          filtered = filtered.filter(product => product.isTextInternationalsUnlimited);
        }
        if (mobileFilters.tech3G) {
          filtered = filtered.filter(product => product.mobileDataGeneration === '3g');
        }
        if (mobileFilters.tech4G) {
          filtered = filtered.filter(product => product.mobileDataGeneration === '4g');
        }
        if (mobileFilters.tech5G) {
          filtered = filtered.filter(product => product.mobileDataGeneration === '5g');
        }
        if (mobileFilters.isDataShared) {
          filtered = filtered.filter(product => product.isDataShared);
        }
        if (mobileFilters.isDataUnlimited) {
          filtered = filtered.filter(product => product.isDataUnlimited);
        } else {
          filtered = filtered.filter(product => product.numGb >= mobileFilters.numGb);
        }
        if (mobileFilters.roaming.length > 0) {
          filtered = filtered.filter(product => {
            return mobileFilters.roaming.some(roamingOption => product.roaming.includes(roamingOption));
          });
        }
      } else if (productType === 'fijo') { // Land line filters
        if (landlineFilters.landlineIsMinsNationalsUnlimited) {
          filtered = filtered.filter(product => product.landlineIsMinsNationalsUnlimited);
        } else {
          filtered = filtered.filter(product => product.landlineNumMinNationals >= landlineFilters.landlineNumMinNationals);
        }

        if (landlineFilters.landlineIsMinsInternationalsUnlimited) {
          filtered = filtered.filter(product => product.landlineIsMinsInternationalsUnlimited);
        } else {
          filtered = filtered.filter(product => product.landlineNumMinInternationals >= landlineFilters.landlineNumMinInternationals);
        }
      } else if (productType === 'fibra') { // Fiber filters
        if (fiberFilters.fibreType) {
          filtered = filtered.filter(product => product.fibreType === fiberFilters.fibreType);
        }
        if (fiberFilters.fibreSpeed) {
          filtered = filtered.filter(product => product.fibreSpeed >= fiberFilters.fibreSpeed);
        }
      }

      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [productType, productsTelco, mobileFilters, landlineFilters, fiberFilters]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedProduct) {
      toast.error("Debe seleccionar un producto");
      return;
    }

    try {
      let response = await axios.post(
        "/api/v2/telco_products/add_product",
        { telco_id: telco_invoice.id, selected_id: selectedProduct.id, provider_id: providerId, invoice_type: invoiceType},
        {
          headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').content,
          },
        }
      );

      if (response.status === 200) {
        setTimeout(() => {
          document.getElementById("closeModal").click();
          window.location.href = "/telco_invoices/" + telco_invoice.id;
        }, 200); // In order to close the modal automatic when some product is added
      } else {
        throw new Error(response.data.errors.join(", "));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          toast.error("Recurso no encontrado. Verifique los datos ingresados.");
        } else if (error.response.status === 500) {
          toast.error("Hubo un error interno en el servidor. Intente nuevamente más tarde.");
        } else {
          toast.error("Hubo un error al procesar la solicitud: " + error.response.data.errors.join(", "));
        }
      } else {
        toast.error("Error de red o de servidor. Intente nuevamente más tarde.");
      }
      console.error(error);
    }
  };

  const changeProductType = (idSet) => { // Reset the filter
    if (idSet.size > 0) {
      const selectedType = idSet.values().next().value;
      setProductType(selectedType);
      setShowProductSelect(true);

      setMobileFilters({
        minsNationalsUnlimited: false,
        minsInternationalsUnlimited: false,
        smsNationalsUnlimited: false,
        smsInternationalsUnlimited: false,
        tech3G: false,
        tech4G: false,
        tech5G: false,
        minsNationals: 0,
        minsInternationals: 0,
        isDataShared: false,
        isDataUnlimited: false,
        numGb: 0,
        roaming: []
      });

      setLandlineFilters({
        landlineIsMinsNationalsUnlimited: false,
        landlineIsMinsInternationalsUnlimited: false,
        landlineNumMinNationals: 0,
        landlineNumMinInternationals: 0,
      });
      setFiberFilters({
        fibreType: null,
        fibreSpeed: 0,
      });
      setSelectedProduct(null);
    } else {
      setProductType(null);
      setShowProductSelect(false);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleMobileFilterChange = (filterType, value) => {
    setMobileFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const handleLandlineFilterChange = (filterType, value) => {
    setLandlineFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const handleFiberFilterChange = (filterType, value) => {
    setFiberFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const resetForm = () => {
    setProductType(null);
    setSelectedProduct(null);
    setShowProductSelect(false);
    setMobileFilters({
      minsNationalsUnlimited: false,
      minsInternationalsUnlimited: false,
      smsNationalsUnlimited: false,
      smsInternationalsUnlimited: false,
      tech3G: false,
      tech4G: false,
      tech5G: false,
      minsNationals: 0,
      minsInternationals: 0,
      isDataShared: false,
      isDataUnlimited: false,
      numGb: 0,
      roaming: []
    });
    setLandlineFilters({
      landlineIsMinsNationalsUnlimited: false,
      landlineIsMinsInternationalsUnlimited: false,
      landlineNumMinNationals: 0,
      landlineNumMinInternationals: 0,
    });
    setFiberFilters({
      fibreType: null,
      fibreSpeed: 100,
    });
  };

  return (
    <>
      <Toaster />
      {telco_invoice && (
        <form onSubmit={handleSubmit} className="addProductForm">
          <Title1 env_color={env_color}>Añadir Producto</Title1>
          <Container>
            <Title2 env_color={env_color}>Tipo de producto</Title2>
            <Select // Dropdown of product type => mobile, land line, fiber
              selectId="productType"
              searchBar={false}
              multiSelect={false}
              dataSelected={productType}
              reactSetter={changeProductType}
              data={[
                { id: 'movil', name: 'Móvil' },
                { id: 'fibra', name: 'Fibra' },
                { id: 'fijo', name: 'Fijo' }
              ]}
              placeholder={"Seleccione el tipo de producto"}
            />
            {showProductSelect && productType && (
              <>
                <Title2 env_color={env_color}>Filtrar por características</Title2>
                <FilterComponents // Executes the filtering corresponding to the product type
                  productType={productType}
                  mobileFilters={mobileFilters}
                  handleMobileFilterChange={handleMobileFilterChange}
                  landlineFilters={landlineFilters}
                  handleLandlineFilterChange={handleLandlineFilterChange}
                  fiberFilters={fiberFilters}
                  handleFiberFilterChange={handleFiberFilterChange}
                  env_color={env_color}
                />
                <ProductSelection // Executes the product selection
                  filteredProducts={filteredProducts}
                  selectedProduct={selectedProduct}
                  handleProductSelect={handleProductSelect}
                  env_color={env_color}
                />
              </>
            )}
          </Container>
          <SubmitButton type="submit" env_color={env_color}>AÑADIR</SubmitButton>
        </form>
      )}
    </>
  );
}

export default AddProductTelco;