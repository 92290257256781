import React from "react";
import styles from "./formStyles.module.css";

//form components
import InformacionPersonal from "./InformacionPersonal";
import ConfigLuz from "./ConfigLuz";
import ConfigGenerica from "./ConfigGenerica";
import ConfigSimulaciones from "./ConfigSimulaciones";
import ConfigComparativas from "./ConfigComparativas";
import ConfigComuComerciales from "./ConfigComuComerciales";
import RecDeImportesOrigen from "./RecDeImportesOrigen";
import PlanesTarifYComparativas from "./PlanesTarifYComparativas";
import Campañas from "./Campañas";
import ComComerciales from "./ComComerciales";

const Index = ({
  organization,
  users,
  mainUserSelectId,
  currentMainUserName,
  socialBondData,
  outgoingEmails,
  currentOutgoingMail,
  currentEmailManager,
  emailManager,
  emailManagerSelectId,
  outgoingMailSelectId,
  topeGasOptions,
  topegasPVPC,
  nonTopeGas,
  selfConsumption,
  selfConsumptionUsed,
  showAccumulatedCompensation,
  annualizationType,
  formInformation,
  brandings,
  ratePlans,
  selectedRatePlans,
  benefits,
  selectedBenefits,
  campaigns,
  selectedCampaigneAsoToOrg,
  flatRates,
  urlSips,
  current_user_roles,
  savings_taxes_logic,
  selectedTemplates,
  templatesData
}) => {
  return (
    <div className={styles.formContainer}>
      <InformacionPersonal
        organization={organization}
        users={users}
        mainUserSelectId={mainUserSelectId}
        currentMainUserName={currentMainUserName}
        outgoingEmails={outgoingEmails}
        currentOutgoingMail={currentOutgoingMail}
        outgoingMailSelectId={outgoingMailSelectId}
        emailManager={emailManager}
        currentEmailManager={currentEmailManager}
        emailManagerSelectId={emailManagerSelectId}
        urlSips = {urlSips}
      />
      <ConfigLuz
        organization={organization}
        socialBondData={socialBondData}
        socialBondSelectId="organization_social_bond_financing"
        topeGasOptions={topeGasOptions}
        topegasPVPC={topegasPVPC}
        nonTopeGas={nonTopeGas}
        selfConsumption={selfConsumption}
        selfConsumptionUsed={selfConsumptionUsed}
        showAccumulatedCompensation={showAccumulatedCompensation}
        annualizationType={annualizationType}
        formInformation={formInformation}
      />
      <ConfigGenerica 
        organization={organization} 
        userRoles={current_user_roles} 
        savingsTaxesLogic={savings_taxes_logic}
        brandings={brandings}
      />
      <ConfigSimulaciones organization={organization} current_user_roles={current_user_roles} />
      <ConfigComparativas organization={organization} current_user_roles={current_user_roles} />
      <ConfigComuComerciales organization={organization} />
      <RecDeImportesOrigen organization={organization} />
      <PlanesTarifYComparativas
        organization={organization}
        ratePlans={ratePlans}
        selectedRatePlans={selectedRatePlans}
        benefits={benefits}
        selectedBenefits={selectedBenefits}
      />
      <Campañas
        organization={organization}
        campaigns={campaigns}
        selectedCampaigneAsoToOrg={selectedCampaigneAsoToOrg}
      />
      <ComComerciales
        organization={organization}
        flatRates={flatRates}
        current_user_roles={current_user_roles}
        selectedTemplates={selectedTemplates}
        templatesData={templatesData}
      />
    </div>
  );
};

export default Index;
