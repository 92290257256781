import React, { useState } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { validationSchema } from "../validations/validationSchema";

//components
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import Modal from "../../../reactComponents/modal/Modal";
import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";

import {findValueMatch} from "../utils/utils"

const ConfigGenerica = ({ 
  organization, 
  userRoles,
  savingsTaxesLogic,
  brandings
}) => {

  const formik = useFormik({
    initialValues: {
      organization: {
        password_expiration_time: organization.password_expiration_time
      },
    },
    validationSchema: validationSchema,
  });

  const [showConfGenModal, setShowConfGenModal] = useState(false);

  const [selectedBranding, setSelectedBranding] = useState(organization.branding_id || '');

  const brandingsData = [
    { id: '', name: 'Ningún branding seleccionado' }, 
    ...brandings.map((item) => ({
      id: item.id,
      name: item.name,
    })),
  ];

  const getBrandingPlaceholder = (selectedBranding, brandingsData) => {
    if (!selectedBranding) {
      return 'Selecciona una opción';
    }
    const selectedItem = brandingsData.find((item) => item.id === selectedBranding);
    return selectedItem ? selectedItem.name : 'Selecciona una opción';
  };

  const configGenericaModalText = [
    {
      header: "¿Permitir modificación de Importe Total?",
      text: "Permite la modificación del campo 'Importe Total' en los formularios de \
      datos de los procesamientos.",
    },
    {
      header: "¿Permitir modificación de información?",
      text: "Permite la modificación de cualquier campo' en los formularios de \
      datos de los procesamientos.",
    },
    {
      header: "¿Permitir procesamientos con Errores?",
      text: "Permite a los usuarios visualizar procesamientos aunque tengan errores \
      de extracción de datos. En caso de desactivar esta opción, entonces no dejará \
      acceder a los formularios y se le mostrará un mensaje informativo al usuario.",
    },
    {
      header: "¿Habilitar Código de Colores en comparativas?",
      text: "Habilita el uso de distintos colores para los botones de los planes \
      en la pantalla final de comparativas.",
    },
    {
      header: "¿Habilitar Aviso de Solicitud de Facturas con factura adjuntada?",
      text: "Funcionalidad que activa un modal de aviso al usuario cada vez que \
      accede al 'registro de peticiones de facturas' con una recordatorio de las \
      solicitudes que tiene SIN PROCESAR.",
    },
  ];

  const [allowTotalInvChanges, setAllowTotalInvChanges] = useState(
    organization.allow_invoice_total_changes
  );
  const [allowEditInvInfo, setAllowEditInvInfo] = useState(
    organization.allow_edit_invoice_information
  );
  const [allowInvProcessWithErr, setAllowInvProcessWithErr] = useState(
    organization.allow_wrong_invoice_processing
  );
  const [allowColourCode, setAllowColourCode] = useState(
    organization.allow_color_code_sav_summary
  );
  const [allowRequestReminder, setAllowRequestReminder] = useState(
    organization.enable_attached_requests_reminder
  );
  const [checkedPassExpire, setCheckedPassExpire] = useState(
    organization.password_expires
  );

  const savingsTaxesLogicData = savingsTaxesLogic.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const savingsTaxesLogicPlaceholder = findValueMatch(
    savingsTaxesLogicData,
    organization.savings_taxes_logic
  );

  const configuracionGenericaCheckboxData = [
    {
      onChange: () => setAllowTotalInvChanges(!allowTotalInvChanges),
      checked: allowTotalInvChanges,
      name: "organization[allow_invoice_total_changes]",
      id: "organization_allow_invoice_total_changes",
      label: "¿Permitir modificación de Importe Total?",
    },
    {
      onChange: () => setAllowEditInvInfo(!allowEditInvInfo),
      checked: allowEditInvInfo,
      name: "organization[allow_edit_invoice_information]",
      id: "organization_allow_edit_invoice_information",
      label: "¿Permitir modificación de información? ",
    },
    {
      onChange: () => setAllowInvProcessWithErr(!allowInvProcessWithErr),
      checked: allowInvProcessWithErr,
      name: "organization[allow_wrong_invoice_processing]",
      id: "organization_allow_wrong_invoice_processing",
      label: "¿Permitir procesamientos con Errores? ",
    },
    {
      onChange: () => setAllowColourCode(!allowColourCode),
      checked: allowColourCode,
      name: "organization[allow_color_code_sav_summary]",
      id: "organization_allow_color_code_sav_summary",
      label: "¿Habilitar Código de Colores en comparativas? ",
    },
    {
      onChange: () => setAllowRequestReminder(!allowRequestReminder),
      checked: allowRequestReminder,
      name: "organization[enable_attached_requests_reminder]",
      id: "organization_enable_attached_requests_reminder",
      label: "¿Habilitar Aviso de Solicitud de Facturas con factura adjuntada? ",
    },
  ];
  return (
    <>
      {showConfGenModal && (
        <Modal
          data={configGenericaModalText}
          onClose={() => setShowConfGenModal(false)}
        />
      )}
      <h3 className={styles.title}>
        Configuración Genérica
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowConfGenModal(true)}
        />
      </h3>

      <div className={styles.configuracionLuzContainer}>
        <div className={styles.confLuz}>
          <div>
            <h5 className={styles.headerColor}>
              Recálculos Factura IVA e IE
            </h5>
            <p className={styles.smallText}>
              Indica la lógica a seguir para el uso del IVA y el IE en las 
              comparativas y recálculo de orígen
            </p>
          </div>

          <Select
            multiSelect={false}
            searchBar={true}
            name=""
            data={savingsTaxesLogicData}
            placeholder={savingsTaxesLogicPlaceholder}
            dataSelected={organization.savings_taxes_logic}
            selectId="organization_savings_taxes_logic"
            selectName="organization[savings_taxes_logic]"
          />
        </div>
      </div>

      <div className={styles.checkboxDivFullWidth}>
        <h6>Permisos</h6>
        <div className={styles.scrollDiv}>
          <div className={styles.checkBoxContent}>
            {configuracionGenericaCheckboxData.map((item) => {
              return (
                <Checkbox
                  key={item.id}
                  onChange={item.onChange}
                  checked={item.checked}
                  name={item.name}
                  id={item.id}
                  label={item.label}
                />
              );
            })}
          </div>
        </div>
      </div>
      { userRoles.some((role) => role.name === "Admin") &&
          <div>
          <h6>Configuración de contraseñas</h6>
          <Checkbox
            label="¿Expiración de contraseñas?"
            onChange={() => setCheckedPassExpire(!checkedPassExpire)}
            checked={checkedPassExpire}
            name="organization[password_expires]"
            id="organization_password_expires"
          />
          {checkedPassExpire &&
            <Input
              label={<>Tiempo de expiración de contraseñas</>}
              type="text"
              name="organization[password_expiration_time]"
              id="organization_password_expiration_time"
              placeholder="3.months"
              value={formik.values.organization.password_expiration_time}
              onChange={formik.handleChange}
              error={formik.errors.organization?.password_expiration_time}
              touched={formik.touched.organization?.password_expiration_time}
            />
          }
          <Select
            multiSelect={false}
            searchBar={true}
            name="Selecciona el tipo de branding"
            data={brandingsData}
            dataSelected={organization.branding_id}
            selectId="organization_branding_id"
            selectName="organization[branding_id]"
            placeholder= {getBrandingPlaceholder(selectedBranding, brandingsData)}
          />
        </div>
      }
    </>
  );
};

export default ConfigGenerica;
