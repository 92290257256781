import React, { useState, useRef, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";

import axios from "axios";
import {
  ModalContainer,
  ModalContent,
  ModalTitle,
  Comments,
  Submit,
  CommentForm,
  CloseModalButton,
} from "../../../../styles/modals";
const ModalHistory = ({ closeModal, ratePlanId, unMarkAllRecords, translations }) => {
  const [contentModal, setContentModal] = useState(null);
  const modalContentRef = useRef();
  useEffect(() => {
    fetchHistory();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  async function fetchHistory() {
    const fetching = await axios.get(
      `/api/v2/rate_plans/${ratePlanId}/history`
    );
    setContentModal(fetching.data.history);
  }
  const handleClickOutside = (e) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target)
    ) {
      closeModal();
      unMarkAllRecords();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const comment = e.target.comment.value;
    try {
      await axios.post(`/api/v2/rate_plans/${ratePlanId}/history`, {
        comment: comment,
      });
      e.target.comment.value = "";
      fetchHistory();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalContainer>
      <ModalContent ref={modalContentRef}>
        <CloseModalButton
          onClick={() => {
            closeModal();
            unMarkAllRecords();
          }}
        >
          {" "}
          <IoCloseSharp size={25} />
        </CloseModalButton>
        <ModalTitle>{translations.title}</ModalTitle>
        <Comments>
          {contentModal ? <>{contentModal}</> : <>{translations.no_comments}</>}
        </Comments>
        <CommentForm
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            style={{
              border: "solid 2px #21103d",
              width: "100%",
              height: "50px",
              textAlign: "start",
            }}
            type="text"
            name="comment"
            placeholder={translations.add_comment}
            required
          />
          <Submit type="submit">{translations.submit}</Submit>
        </CommentForm>
      </ModalContent>
    </ModalContainer>
  );
};

export default ModalHistory;
