import React, { useState } from "react";
import {
  TypesContainer,
  ExportButton,
  ExportsContainer,
} from "../../../../styles/rate_plans";
import { FiPlusCircle } from "react-icons/fi";
import { CgImport } from "react-icons/cg";

import { CgExport } from "react-icons/cg";

export const Exports = ({ status, translations }) => {
  const [showTypes, setShowTypes] = useState(false);
  const exportPlans = (type) => {
    window.location.href = "/admin/rate_plans/export/" + type + ".xlsx" + "?status=" + status;
    setShowTypes(false);
  };
  return (
    <>
      <ExportsContainer>
        <ExportButton onClick={() => setShowTypes(!showTypes)} href="#">
          <CgImport style={{ marginBottom: "3px" }} size={20} />
          {translations.export}
        </ExportButton>
        {showTypes ? (
          <TypesContainer>
            <div
              onClick={() => exportPlans("luz-2x")}
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              {translations.electric_2x}
            </div>
            <div onClick={() => exportPlans("luz-3x")}>{translations.electric_3x}</div>
            <div onClick={() => exportPlans("luz-6x")}>{translations.electric_6x}</div>
            <div onClick={() => exportPlans("gas")}>{translations.gas}</div>
            <div onClick={() => exportPlans("dual")}>{translations.dual}</div>
            <div
              onClick={() => exportPlans("telco")}
              style={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              {translations.telco}
            </div>
          </TypesContainer>
        ) : null}

        <ExportButton target="_blank" href="/admin/data_imports/new">
          <CgExport style={{ marginBottom: "3px" }} size={20} />
          {translations.import}
        </ExportButton>
        <ExportButton target="_blank" href="/admin/rate_plans/new">
          <FiPlusCircle
            style={{ marginBottom: "3px", marginRight: "2px" }}
            size={20}
          />
          {translations.new_rate_plan}
        </ExportButton>
      </ExportsContainer>
    </>
  );
};
