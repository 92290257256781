import React, { useState } from "react";
import styles from "./historicoTarifario.module.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { createRoot } from "react-dom/client";

const HistoricoTarifarioLuz = ({ electricPricesData, electricPricesTranslations }) => {
  const [toggle, setToggle] = useState(false);
  const handleToggleClick = () => {
    setToggle(!toggle);
  };

  const pricesElectric = (electricPricesData) => {

    return (
      <div key={electricPricesData.id} className={styles.preciosContainer}>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.power_supplied_allowance}&nbsp;</span>
          {electricPricesData.power_supplied_allowance}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.dh_type}&nbsp;</span>
          {electricPricesData.dh_type}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.commission_id}&nbsp;</span>
          {electricPricesData.commission_id}
        </p>

        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.power_price_p1}&nbsp;</span>
          {electricPricesData.power_price_p1} {electricPricesTranslations.currency}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.power_price_p2}&nbsp;</span>
          {electricPricesData.power_price_p2} {electricPricesTranslations.currency}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.energy_price_p1}&nbsp;</span>
          {electricPricesData.energy_price_p1} {electricPricesTranslations.currency}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.energy_price_p2}&nbsp;</span>
          {electricPricesData.energy_price_p2} {electricPricesTranslations.currency}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.energy_price_p3}&nbsp;</span>
          {electricPricesData.energy_price_p3} {electricPricesTranslations.currency}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.created_at}&nbsp;</span>
          {electricPricesData.created_at}
        </p>
        <p>
          <span className={styles.tarifaDetails}>{electricPricesTranslations.closed_date}&nbsp;</span>
          {electricPricesData.closed_date}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.historicoContainer}>
      <p onClick={handleToggleClick} className={styles.button}>
        {electricPricesTranslations.title}
        {toggle ? (
          <IoMdArrowDropup className={styles.icon} />
        ) : (
          <IoMdArrowDropdown className={styles.icon} />
        )}
      </p>
      {toggle && electricPricesData.map(pricesElectric)}
    </div>
  );
};

export default HistoricoTarifarioLuz;
