import React from "react";
import { Container, Title } from "../../../../styles/rate_plans";
import { Table } from "./Table";
import useTableData from "../../../../hooks/useTableData";
import { Searcher } from "./Searcher";
import { ToolBar } from "./ToolBar";
import { Exports } from "./Exports";
const Index = ({ translations }) => {
  const {
    data,
    fetchData,
    markedRecords,
    markRecord,
    unMarkAllRecords,
    filters,
    setFilters,
  } = useTableData({
    url: "/admin/rate_plans/index_json/",
  });

  return (
    <Container>
      <Title>{translations.title}</Title>
      <Searcher
        unMarkAllRecords={unMarkAllRecords}
        filters={filters}
        setFilters={setFilters}
        translations={translations.search}
      />
      <Exports
        status={filters.status}
        translations={translations.exports}
      />
      <ToolBar
        unMarkAllRecords={unMarkAllRecords}
        markedRecords={markedRecords}
        fetchData={fetchData}
        status={filters.status}
        translations={translations.tool_bar}
      />
      {data != null ? (
        <Table
          data={data}
          markedRecords={markedRecords}
          markRecord={markRecord}
          translations={translations.table}
        />
      ) : (
        <div style={{ marginLeft: "3%" }}>
          {translations.no_data}
        </div>
      )}
    </Container>
  );
};

export default Index;
