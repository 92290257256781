import React, {useRef, useEffect} from "react";
import {
  ModalContainer,
  ModalContent,
  ModalTitle,
  CloseModalButton,
  Ul,
  Submit,
} from "../../../styles/modals";
import { IoCloseSharp } from "react-icons/io5";

export const ModalInfo = ({  closeModal, translations }) => {
  const modalContentRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
    const handleClickOutside = (e) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target)
    ) {
      closeModal();
    }
  };
  return (
    <ModalContainer>
      <ModalContent ref={modalContentRef}>
        <ModalTitle>{translations.modal_info.title}</ModalTitle>
        <CloseModalButton onClick={() => closeModal()}>
          <IoCloseSharp size={25} />{" "}
        </CloseModalButton>
        <Ul>
          <li>{translations.modal_info.list.item_1}</li>
          <li>{translations.modal_info.list.item_2}</li>
          <li>{translations.modal_info.list.item_3}</li>
        </Ul>
        <div style={{ width: "100px", margin: "-10px auto 15px auto" }}>
          <Submit
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
             {translations.modal_info.button}
          </Submit>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};
