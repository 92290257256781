import React, { useRef, useEffect } from "react";
import {
  ModalContainer,
  ModalContent,
  ModalTitle,
  CloseModalButton,
  Submit,
} from "../../../styles/modals";
import { IoCloseSharp } from "react-icons/io5";

export const ModalError = ({ errorInfo, closeModal, translations }) => {
  console.log(
    "🚀 ~ file: ModalError.jsx:11 ~ ModalError ~ errorInfo:",
    errorInfo
  );
  const modalContentRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (e) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target)
    ) {
      closeModal();
    }
  };
  return (
    <ModalContainer>
      <ModalContent ref={modalContentRef}>
        <ModalTitle>{translations.modal_error}</ModalTitle>
        <CloseModalButton onClick={() => closeModal()}>
          <IoCloseSharp size={25} />{" "}
        </CloseModalButton>
        <div
          style={{
            margin: "25px auto 25px 20px",
          }}
        >
          {errorInfo.response.data.message}
        </div>
        <div style={{ width: "100px", margin: "-10px auto 15px auto" }}>
          <Submit
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            {translations.modal_info.button}
          </Submit>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};
