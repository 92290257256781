import React, { useState, useEffect } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import {validationSchema} from "../validations/validationSchema"
import { AiOutlineInfoCircle } from "react-icons/ai";

//components
import Input from "../../../reactComponents/input/Input";
import TextArea from "../../../reactComponents/textArea/TextArea";
import Select from "../../../reactComponents/select/Select";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import Modal from "../../../reactComponents/modal/Modal";

//adapter
import { findValueMatch } from "../utils/utils";

const configComparativas = ({ organization, current_user_roles }) => {
  const formik = useFormik({
    initialValues: {
      organization: {
        cut_date_luz: organization.cut_date_luz,
        cut_date_luz_3x: organization.cut_date_luz_3x,
        cut_date_gas: organization.cut_date_gas,
        r_plan_info_display_fmt_svgs: 
        organization.r_plan_info_display_fmt_svgs
      },
    },
    validationSchema: validationSchema,
  });

  const userIsAdmin = () => {
    return current_user_roles.some((rol) => rol.name === "Admin");
  };

  //modal
  const [showConfComparativaModal, setShowConfComparativaModal] =
    useState(false);

  const configComparativasModalData = [
    {
      header: "¿Unificar excel Datos de Contratación en Exportación de Registro de Procesamientos?",
      text: "Añade los datos de contratación a la exportación del registro de procesamientos",
    },
    {
      header: "¿Mostrar ahorros negativos en comparativa?",
      text: "Habilita que se muestren las comparativas con ahorro negativo, es decir, las comparativas donde no se encuentre ahorro.",
    },
    {
      header: "¿Mostrar el ahorro anual cuando es negativo?",
      text: "Habilita que se muestre el mensaje con las pontencias contratadas y las demandadas en la pantalla de comparativa.",
    },
    {
      header: "¿Mostras potencias contratadas y demandadas en la comparativa?",
      text: "Habilita que se muestre el mensaje del ahorro anual cuando este sea negativo.",
    },
    {
      header: "¿Permitir generación de comparativas de Luz y Gas en procesamientos Duales?",
      text: "Habilita la funcionalidad para calculo de ahorro en las partes de Luz y Gas de forma separada en las comparativas duales",
    },
    {
      header: "¿Mostrar periodo de facturación en comparativas?",
      text: "Habilita que se muestre el periodo de facturación en la pantalla de comparativa",
    },
    {
      header: "¿Mostrar comparativas por defecto?",
      text: "Habilita que se muestre el primer ahorro por defecto una vez se calcula el ahorro",
    },
  ];

  const [unifyContractEnergyExp, setUnifyContractEnergyExp] = useState(
    organization.unify_contract_energy_export
  );
  const [showNegativeSavings, setShowNegativeSavings] = useState(
    organization.show_negative_savings
  );
  const [showNegAnualSavings, setShowNegAnualSavings] = useState(
    organization.show_negative_annual_savings
  );
  const [showPowerCalc, setShowPowerCalc] = useState(
    organization.see_power_s_calc
  );
  const [allowSepSavings, setAllowSepSavings] = useState(
    organization.allow_separate_savings_on_dual
  );
  const [showInvoiceDatesOnForm, setShowInvoiceDatesOnForm] = useState(
    organization.show_invoice_dates_on_form
  );
  const [showSavingByDefault, setshowSavingByDefault] = useState(
    organization.show_saving_by_default
  );

  const [checkedMultipleSavingsMode, setCheckedMultipleSavingsMode] = useState(
    organization.multiple_savings_mode
  )
  const confComparativasCheckboxData = [
    {
      onChange: () => setUnifyContractEnergyExp(!unifyContractEnergyExp),
      checked: unifyContractEnergyExp,
      name: "organization[unify_contract_energy_export]",
      id: "organization_allow_detailed_simulations",
      label: "¿Unificar excel Datos de Contratación y luz?",
    },
    {
      onChange: () => setShowNegativeSavings(!showNegativeSavings),
      checked: showNegativeSavings,
      name: "organization[show_negative_savings]",
      id: "organization_show_negative_savings",
      label: "¿Mostrar Ahorros Negativos en comparativa?",
    },
    {
      onChange: () => setShowNegAnualSavings(!showNegAnualSavings),
      checked: showNegAnualSavings,
      name: "organization[show_negative_annual_savings]",
      id: "organization_show_negative_annual_savings",
      label: "¿Mostrar el ahorro anual cuando es negativo?",
    },
    {
      onChange: () => setShowPowerCalc(!showPowerCalc),
      checked: showPowerCalc,
      name: "organization[see_power_s_calc]",
      id: "organization_see_power_s_calc",
      label: "¿Mostras potencias contratadas y demandadas en la comparativa?",
    },
    {
      onChange: () => setAllowSepSavings(!allowSepSavings),
      checked: allowSepSavings,
      name: "organization[allow_separate_savings_on_dual]",
      id: "organization_allow_separate_savings_on_dual",
      label:
        "¿Permitir generación de comparativas de Luz y Gas en procesamientos Duales?",
    },
    {
      onChange: () => setShowInvoiceDatesOnForm(!showInvoiceDatesOnForm),
      checked: showInvoiceDatesOnForm,
      name: "organization[show_invoice_dates_on_form]",
      id: "organization_show_invoice_dates_on_form",
      label:
        "¿Mostrar periodo de facturación en comparativas?",
    },
    {
      onChange: () => setshowSavingByDefault(!showSavingByDefault),
      checked: showSavingByDefault,
      name: "organization[show_saving_by_default]",
      id: "organization_show_saving_by_default",
      label:
        "¿Mostrar comparativa por defecto?",
    },
  ];

  const planViewOpt = [
    {
      id: "Desplegable",
      name: "Desplegable",
    },
    {
      id: "Botones",
      name: "Botones",
    },
  ];

  const cutDateTypeOpt = [
    {
      id: "fixed",
      name: "Fijo",
    },
    {
      id: "dynamic",
      name: "Dinamico",
    },
  ];

  const savingsOrderOpt = [
    {
      id: "asc",
      name: "Ahorro ascendente",
    },
    {
      id: "desc",
      name: "Ahorro descendente",
    },
    {
      id: "asc_priority",
      name: "Prioridad ascendente",
    },
    {
      id: "desc_priority",
      name: "Prioridad descendente",
    },
  ];

  const serviceDualInvoicesOpt = [
    {
      id: "electric",
      name: "Luz",
    },
    {
      id: "gas",
      name: "Gas",
    },
    {
      id: "electric/gas",
      name: "Luz/Gas - 50%/50%",
    },
  ];

  const createArrayWithYearMonthObjects = () => {
    const array = [];
    for (let i = 1; i <= 24; i++) {
      array.push({ id: i, name: i, value: i });
    }
    return array;
  };

  const resultArrayYear = createArrayWithYearMonthObjects();

  //dates
  const cutDateType = organization.cut_date_type;

  const planViewPlaceholder = findValueMatch(
    planViewOpt,
    organization.plan_view
  );

  const cutDateTypePlaceholder = findValueMatch(
    cutDateTypeOpt,
    organization.cut_date_type
  );
  const savingsOrderPlaceholder = findValueMatch(
    savingsOrderOpt,
    organization.savings_order
  );
  const serviceDualInvoicesPlaceholder = findValueMatch(
    serviceDualInvoicesOpt,
    organization.services_dual_invoices
  );
  let [selectedValue, setSelectedValue] = useState(cutDateType);
  const onSelectValueFunction = (item) => {
    const iterator = item.values();
    const firstValue = iterator.next().value;
    setSelectedValue(firstValue);
  };

  return (
    <>
      {showConfComparativaModal && (
        <Modal
          data={configComparativasModalData}
          onClose={() => setShowConfComparativaModal(false)}
        />
      )}
      <h3 className={styles.title}>
        Configuración Comparativas
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowConfComparativaModal(true)}
        />
      </h3>
      <h6>Configuración Comparativas</h6>
      <div className={styles.scrollDiv}>
        <div className={styles.checkBoxContent}>
          {confComparativasCheckboxData.map((item) => {
            return (
              <Checkbox
                key={item.id}
                onChange={item.onChange}
                checked={item.checked}
                name={item.name}
                id={item.id}
                label={item.label}
              />
            );
          })}
        </div>
      </div>

      { userIsAdmin() &&
        <div>
          <h6> Configuración múltiples comparativas </h6>
          <Checkbox
            label="¿Activar servicio 'multicomparativa'?"
            onChange={() => setCheckedMultipleSavingsMode(!checkedMultipleSavingsMode)}
            checked={checkedMultipleSavingsMode}
            name="organization[multiple_savings_mode]"
            id="organization_multiple_savings_mode"
          />
        </div>
      }

      <Select
        multiSelect={false}
        searchBar={true}
        name="Vista de los planes"
        data={planViewOpt}
        dataSelected={organization.plan_view}
        placeholder={planViewPlaceholder}
        selectId="organization_plan_view"
        selectName="organization[plan_view]"
      />
      
      {userIsAdmin() &&
        <>
          <TextArea
            labelText="Formato de visualización de datos de las tarifas"
            name="organization[r_plan_info_display_fmt_svgs]"
            id="organization_r_plan_info_display_fmt_svgs"
            required="required"
            placeholder={organization.r_plan_info_display_fmt_svgs}
            defaultValue={formik.values.organization.r_plan_info_display_fmt_svgs}
            value={formik.values.organization.r_plan_info_display_fmt_inform_pr}
            onChange={formik.handleChange}
            error={formik.errors.organization?.r_plan_info_display_fmt_svgs}
            touched={formik.touched.organization?.r_plan_info_display_fmt_svgs}
          />
        </>
      }

      <Select
        multiSelect={false}
        searchBar={false}
        name="Elige el tipo de orden a aplicar en las comparativas"
        data={savingsOrderOpt}
        dataSelected={organization.savings_order}
        placeholder={savingsOrderPlaceholder}
        selectId="organization_savings_order"
        selectName="organization[savings_order]"
        classNameName={styles.headerColor}
      />
      <Select
        multiSelect={false}
        searchBar={true}
        name="Elige el tipo de corte para la fecha de las facturas (por defecto hay una fecha fija)."
        data={cutDateTypeOpt}
        dataSelected={selectedValue}
        placeholder={cutDateTypePlaceholder}
        selectId="organization_cut_date_type"
        selectName="organization[cut_date_type]"
        reactSetter={onSelectValueFunction}
      />

      {selectedValue === "fixed" ? (
        <div className={styles.dateContainer}>
          <div className={styles.dateContent}>
            <div>
              <label htmlFor="cut_date_luz">Fecha corte Luz 2x</label>
              <p className={styles.smallText}>
                Facturas previas a la fecha especificada no se podrán utilizar
                para comparativas de Luz en eSave.
              </p>
            </div>
            <Input
              type="date"
              id="organization_cut_date_luz"
              name="organization[cut_date_luz]"
              value={formik.values.organization.cut_date_luz}
              onChange={formik.handleChange}
              error={formik.errors.organization?.cut_date_luz}
              touched={formik.touched.organization?.cut_date_luz}
            />
          </div>
          <div className={styles.dateContent}>
            <div>
              <label htmlFor="cut_date_luz">Fecha corte Luz 3x</label>
              <p className={styles.smallText}>
                Facturas previas a la fecha especificada no se podrán utilizar
                para comparativas de Luz 3.0TD en eSave.
              </p>
            </div>
            <Input
              type="date"
              id="organization_cut_date_luz_3x"
              name="organization[cut_date_luz_3x]"
              value={formik.values.organization.cut_date_luz_3x}
              onChange={formik.handleChange}
              error={formik.errors.organization?.cut_date_luz_3x}
              touched={formik.touched.organization?.cut_date_luz_3x}
            />
          </div>
          <div className={styles.dateContent}>
            <div>
              <label htmlFor="cut_date_luz">Fecha corte Gas</label>
              <p className={styles.smallText}>
                Facturas previas a la fecha especificada no se podrán utilizar
                para comparativas de Gas en eSave.
              </p>
            </div>
            <Input
              type="date"
              id="organization_cut_date_gas"
              name="organization[cut_date_gas]"
              value={formik.values.organization.cut_date_gas}
              onChange={formik.handleChange}
              error={formik.errors.organization?.cut_date_gas}
              touched={formik.touched.organization?.cut_date_gas}
            />
          </div>
        </div>
      ) : (
        <Select
          multiSelect={false}
          searchBar={true}
          name="Fecha de corte dinamica: Unicamente se procesan las facturas que se hayan generado en los siguientes meses anteriores:"
          data={resultArrayYear}
          placeholder={organization.cut_date_dynamic}
          dataSelected={organization.cut_date_dynamic}
          selectId="organization_cut_date_dynamic"
          selectName="organization[cut_date_dynamic]"
        />
      )}
      <Select 
        multiSelect={false}
        searchBar={true}
        name="Elige el modo de aplicar los servicios en las comparativas"
        data={serviceDualInvoicesOpt}
        dataSelected={organization.services_dual_invoices}
        placeholder={serviceDualInvoicesPlaceholder}
        selectId="organization_services_dual_invoices"
        selectName="organization[services_dual_invoices]"
      />
    </>
  );
};

export default configComparativas;
