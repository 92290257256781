import React, { useState, useRef, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";

import {
  ModalContainer,
  ModalContent,
  ModalTitle,
  FormRow,
  Submit,
  CommentForm,
  CloseModalButton,
} from "../../../../styles/modals";
const ModalExportSavings = ({
  closeModal,
  markedRecords,
  unMarkAllRecords,
  translations
}) => {
  const currentDate = new Date();
  const iDate = new Date(currentDate);
  iDate.setMonth(currentDate.getMonth() - 1);

  const formattedInitDate = iDate.toISOString().split("T")[0];
  const formattedEndDate = currentDate.toISOString().split("T")[0];

  const [initDate, setInitDate] = useState(formattedInitDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  const modalContentRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target)
    ) {
      closeModal();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const ratePlanIds = markedRecords.join(",");
      const url = `/admin/rate_plans/export/saving_calculation.xlsx?rate_plan_ids=${ratePlanIds}&init_date=${initDate}&end_date=${endDate}&positive_saving=${e.target.positiveSaving.checked}`;
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
    closeModal();
    unMarkAllRecords();
  };

  return (
    <ModalContainer>
      <ModalContent ref={modalContentRef}>
        <CloseModalButton onClick={() => closeModal()}>
          {" "}
          <IoCloseSharp size={25} />
        </CloseModalButton>
        <ModalTitle style={{ textAlign: "center" }}>
          {translations.title}
        </ModalTitle>
        <CommentForm
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onSubmit={(e) => handleSubmit(e)}
        >
          <FormRow>
            <label style={{ width: "150px" }}>
              {translations.init_date}
              <input
                style={{ border: "solid 2px #21103d" }}
                type="date"
                value={initDate}
                name="init_date"
                required
                onChange={(e) => setInitDate(e.target.value)}
              />
            </label>
            <label style={{ width: "150px" }}>
              {translations.end_date}
              <input
                style={{ border: "solid 2px #21103d" }}
                type="date"
                value={endDate}
                name="end_date"
                required
                onChange={(e) => setEndDate(e.target.value)}
              />
            </label>
          </FormRow>
          {translations.only_positive}
          <input type="checkbox" name="positiveSaving" />
          <Submit style={{ marginTop: "30px" }} type="submit">
          {translations.submit}
          </Submit>
        </CommentForm>
      </ModalContent>
    </ModalContainer>
  );
};

export default ModalExportSavings;
