import React, { useState, useRef } from "react";
import { FaCircleInfo } from "react-icons/fa6";
import { FaExclamationTriangle } from 'react-icons/fa';

import axios from "axios";
import FilesMap from "./FilesMap";
import {
  UploadInput,
  Label,
  P,
  Form,
  MainButton,
  SubmitButton,
  Container,
  InfoBox
} from "../../../styles/individualComponents/add_form";
import useCustomModal from "../../../hooks/useCustomModal";
import { ModalError } from "./ModalError";
import { ModalInfo } from "./ModalInfo";
import { ModalCountryNotAllowed } from './ModalCountryNotAllowed';
import toast, { Toaster } from "react-hot-toast";
import Select from "../../reactComponents/select/Select";

const Ahorro = ({env_color, allows,translations,enabled_countries}) => {
  const [file, setfile] = useState([]);
  const [fileElectricity, setFileElectricity] = useState([]); // Para los archivos de electricidad
  const [fileGas, setFileGas] = useState([]); // Para los archivos de gas
  const [isDragging, setIsDragging] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState("6");  // Estado para el tipo de factura
  const [dualOption, setDualOption] = useState(false);//Checkbox facturas dividdidas(dual)
  const fileInputRef = useRef(null);
  const fileInputRefElectricity = useRef(null);
  const fileInputRefGas = useRef(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const handleAttachClick = (type) => {
    if (type === "electricity" && fileInputRefElectricity.current) {
        fileInputRefElectricity.current.click();
    } else if (type === "gas" && fileInputRefGas.current) {
        fileInputRefGas.current.click();
    }
    else if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const ErrorModal = useCustomModal();
  const InfoModal = useCustomModal();
  const CountryModal = useCustomModal(false);

function postData(data) {
  // Apply the overlay
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Adjust the transparency as needed
  overlay.style.zIndex = '1031';
  document.body.appendChild(overlay);

  const loadingToastId = toast.loading(translations.processing_invoice, {
    style: {
      background: env_color,
      color: '#fff',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      fontWeight: 'bold',
      marginTop: '40vh',
      zIndex: '10032',
    },
  });

  axios
    .post("/api/v2/parse-energy-invoice", data)
    .then((res) => {
      const country = res.data.country;  // País de la factura
      // Comprobamos si el país está habilitado
      if (country && !enabled_countries.includes(country)) {
        CountryModal.openModal();
        document.body.removeChild(overlay);
        toast.dismiss(loadingToastId);
      } else {
        // Si el país está habilitado, procedemos con el procesamiento
        let id = res.data.idRegistro ? res.data.idRegistro : res.data.registerId;
        if (id) {
          window.location.href = `/energy_invoices/${id}`;
        } else {
          toast.error(translations.no_invoice_id);
          document.body.removeChild(overlay);
          toast.dismiss(loadingToastId);
        }
      }
    })
    .catch((error) => {
      document.body.removeChild(overlay);
      toast.dismiss(loadingToastId);
      ErrorModal.openModal();
      setErrorInfo(error);
      setfile([]);
    });
}


  const handleSubmit = (e, type) => {
    const invoiceTypeToSend = invoiceTypes.find((type) => type.id === selectedInvoiceType)?.type || '';
    e.preventDefault();
    if (file.length > 5 || fileElectricity.length >5 || fileGas.length > 5) {
      alert(translations.alerts.too_many_files);
    } else if (file.length == 0 && (fileElectricity.length == 0 || fileGas.length==0)) {
      //window.location.href = `/energy_invoices/new/electric_2x_invoice`;
      alert(translations.alerts.no_files);
    } else {
      let form = new FormData();
      let fileCounter = 1;
      // Si el tipo es "dual", procesamos los archivos de electricidad y gas
        if (type == "dual") {
          fileElectricity.forEach((element) => {
            form.append(`subidaFichero${fileCounter}`, element, element.name);
            fileCounter++;  // Incrementamos el contador para los archivos de electricidad
          });
          fileCounter = 1;
          fileGas.forEach((element) => {
            form.append(`subidaFichero${fileCounter}Gas`, element, element.name);
            fileCounter++;  // Incrementamos el contador para los archivos de gas
          });
        } else{
          file.forEach((element) => {
            form.append(`subidaFichero${fileCounter}`, element, element.name);
            fileCounter++;
          });
        }

      form.append("extractContractInfo", "no");
      form.append("extractSavings", "no");
      form.append("contactPerson", "null");
      form.append("phoneNumber", "null");
      form.append("inbound_source","processed_with_invoice")

      if(invoiceTypeToSend != ""){
      form.append("invoiceType", invoiceTypeToSend);
      }

      postData(form);
    }
  };

  const saveFile = (e,type) => {
    const FilesArr = Array.from(e.target.files);
    const filesAdmited = [];
    FilesArr.map((archivo) => {
      if (archivo.size > 5000000) {
        alert(translations.alerts.file_size_limit);
        return;
      }
      if (
        archivo.type !== "application/pdf" &&
        archivo.type !== "image/png" &&
        archivo.type !== "image/jpeg" &&
        archivo.type !== "image/webp"
      ) {
        alert(translations.alerts.invalid_file_type);
        return;
      }
      filesAdmited.push(archivo);
    });
    if (filesAdmited.length != 0) {
      if (dualOption) {
        if (type === 'electricity') {
          setFileElectricity([...fileElectricity, ...filesAdmited]);
        }
        else if (type === 'gas') {
          setFileGas([...fileGas, ...filesAdmited]);
        }
      } else {
        setfile([...file, ...filesAdmited]);

      }
    }
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    setIsDragging(false);
  };

  const handleManualDataEntry = () => {
    const selectedInvoice = invoiceTypes.find(
      (type) => type.id === selectedInvoiceType
    );
    if (!selectedInvoice || selectedInvoice.type === "") {
      alert(translations.alerts.invalid_invoice_type);
      return;
    }

    const routes = {
      electric: "/energy_invoices/new/electric_2x_invoice",
      gas: "/energy_invoices/new/gas_invoice",
      dual: "/energy_invoices/new/dual_invoice",
      pyme: "/energy_invoices/new/electric_pyme_invoice",
    };
    const route = routes[selectedInvoice.type];
    window.location.href = route;

  };

  //Tipologia permitida por organización
  const invoiceTypes = [
    { id: '1', name: translations.invoice_types.electric_2x, allow: allows.allow_2x, type: 'electric' },
    { id: '2', name: translations.invoice_types.gas, allow: allows.allow_gas, type: 'gas' },
    { id: '3', name: translations.invoice_types.dual, allow: allows.allow_dual, type: 'dual' },
    { id: '4', name: translations.invoice_types.electric_3x, allow: allows.allow_3x, type: 'pyme' },
    { id: '5', name: translations.invoice_types.electric_6x, allow: allows.allow_6x, type: 'pyme' },
    { id: '6', name: translations.invoice_types.auto_detection, allow: true, type: '' }
  ].filter(type => type.allow);

  return (
    <div>
      <Container>
        <Toaster />
        {ErrorModal.showModal ? (
          <ModalError errorInfo={errorInfo} closeModal={ErrorModal.closeModal} translations={translations} />
        ) : null}
        {InfoModal.showModal ? (
          <ModalInfo closeModal={InfoModal.closeModal} translations={translations}/>
        ) : null}
        {CountryModal.showModal ? (
        <ModalCountryNotAllowed closeModal={CountryModal.closeModal} translations={translations} />
        ) : null}
        {dualOption && selectedInvoiceType === '3' ? (
            // CHECKBOX MARCADO FACTURAS SEPARADAS
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: `4px solid ${env_color}`,
                padding: "20px",
                borderRadius: "25px",
                width: "100%",
                maxWidth: "1140px",
              }}
            >
              <P env_color={env_color}>
                {translations.step_1}: {translations.description_1}{" "}
                <FaCircleInfo
                  color={env_color}
                  size={26}
                  cursor="pointer"
                  onClick={(e) => InfoModal.openModal()}
                  style={{ marginBottom: "5px", marginLeft: "10px" }}
                />
              </P>
              <div style={{ display: "flex", gap: "20px" }}>
                {/* Formulario para la factura de luz */}
                <Form
                  env_color={env_color}
                  thinBorder={true}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <P env_color={env_color} smallFontSize>{translations.dual_checkbox.electric}</P>
                  <MainButton env_color={env_color} onClick={() => handleAttachClick("electricity")} type="button">
                    {translations.upload_invoice}
                  </MainButton>
                  <FilesMap setfile={setFileElectricity} files={fileElectricity} />
                  <Label>
                    <UploadInput
                      ref={fileInputRefElectricity}
                      accept=".pdf, .jpg, .jpeg, .png, .webp"
                      onChange={(e) => saveFile(e, "electricity")} // Pasamos "electricity" como segundo parámetro
                      type="file"
                      multiple
                    />
                  </Label>
                </Form>
                {/* Formulario para la factura de gas */}
                <Form
                  env_color={env_color}
                  thinBorder={true}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <P env_color={env_color} smallFontSize>{translations.dual_checkbox.gas}</P>
                  <MainButton env_color={env_color} onClick={() => handleAttachClick("gas")} type="button">
                    {translations.upload_invoice}
                  </MainButton>
                  <FilesMap setfile={setFileGas} files={fileGas} />
                  <Label>
                    <UploadInput
                      ref={fileInputRefGas}
                      accept=".pdf, .jpg, .jpeg, .png, .webp"
                      onChange={(e) => saveFile(e, "gas")} // Pasamos "gas" como segundo parámetro
                      type="file"
                      multiple
                    />
                  </Label>
                </Form>
              </div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
              <SubmitButton
                type="button"
                onClick={(e) => handleSubmit(e, "dual")}
              >
                {translations.process}
              </SubmitButton>
              </div>
            </div>
          ) : (
          <Form
            env_color={env_color}
            isDragging={isDragging}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onSubmit={(e) => handleSubmit(e)}
          >
          <P env_color={env_color}>
            {translations.step_1}: {translations.description_1}{" "}
            <FaCircleInfo
              color={env_color}
              size={26}
              cursor="pointer"
              onClick={(e) => InfoModal.openModal()}
              style={{ marginBottom: "5px", marginLeft: "10px" }}
            />
          </P>
            <MainButton env_color={env_color} onClick={handleAttachClick} type="button">
              {translations.upload_invoice}
            </MainButton>
            <FilesMap setfile={setfile} files={file} />
            <SubmitButton type="submit">{translations.process}</SubmitButton>
            <Label>
              <UploadInput
                ref={fileInputRef}
                accept=".pdf, .jpg, .jpeg, .png, .webp"
                onChange={(e) => saveFile(e)}
                type="file"
                multiple
              />
            </Label>
          </Form>
        )}
      </Container>
      <div style={{display: "flex", flexDirection: "column",alignItems: "center", width: "50%", marginTop: "20px", margin: "20px auto",textAlign: "center" }}>        <Select
            data={invoiceTypes} // Datos que pasan las opciones
            dataSelected={selectedInvoiceType} // Valor seleccionado
            selectId="invoice-type-select"
            selectName="invoiceType"
            name={translations.invoice_types.title}
            placeholder={translations.invoice_types.auto_detection}
            reactSetter={(newSelectedValue) => {
              const selectedValue = [...newSelectedValue][0];
              setSelectedInvoiceType(selectedValue); // Actualizar el estado con el tipo de factura
            }}
          />
          {selectedInvoiceType === '3' && (
            <label>
              <input
                type="checkbox"
                checked={dualOption}
                onChange={() => setDualOption(!dualOption)}
              />
              {translations.dual_checkbox.title}
            </label>
          )}
          {allows.allow_dual && (
            <InfoBox env_color={env_color}>
              <p>
                <FaExclamationTriangle style={{ color: 'red', fontSize: '20px', marginRight: '10px' }} />
                {translations.dual_warning}
              </p>
            </InfoBox>
          )}
        <div>
          {allows.allow_manual_saving_calculations && (
            <MainButton env_color={env_color} style={{ marginTop: "20px"}} onClick={handleManualDataEntry}>
              {translations.manual_data_entry}
            </MainButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ahorro;
